import { Link, PageProps } from "gatsby";
import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import Image from "../components/Image";
import BackgroundImage from "../components/BackgroundImage";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname}>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="px-3 px-sm-5 px-md-0 my-3">
            <Image name="home_hero" className="round" />
          </div>
        </div>
        <div className="col-md-6 mt-md-3 mt-lg-0 mb-5 mb-lg-0">
          <div className="px-sm-3 h-100 d-flex align-items-center">
            <div>
              <h1 className="mt-3 mt-md-0 mb-5 text-center text-md-left">
                Manufacturers of Custom Timber Doors and Bench Tops
              </h1>
              <p>
                Wood ‘N’ Doors is one of South Australia’s largest manufacturers
                of timber products.
              </p>
              <p>
                We have over 20 years experience working with timber. All of our
                items are custom made to our clients unique specifications.
              </p>
              <div className="text-center text-md-left">
                <Link className="btn btn-outline-dark px-3 my-3" to="/products">
                  Our Products
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container my-3 my-lg-5 py-3">
      <div className="row">
        <div className="col-6 col-md-3">
          <Link
            className="my-3 text-decoration-none"
            to="/products/door-styles/"
          >
            <Image name="door_styles" />
            <h3 className="text-center">Door Styles</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link className="my-3 text-decoration-none" to="/products/benchtops/">
            <Image name="benchtops" />
            <h3 className="text-center">Benchtops</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link className="my-3 text-decoration-none" to="/products/joinery/">
            <Image name="joinery" />
            <h3 className="text-center">Joinery</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link
            className="my-3 text-decoration-none"
            to="/products/internal-external-doors/"
          >
            <Image name="int_ext_doors" />
            <h3 className="text-center">Internal & External Doors</h3>
          </Link>
        </div>
      </div>
    </div>

    <div className="container my-3 my-lg-5 px-0">
      <BackgroundImage
        name="why_timber_cover"
        className="wide text-white mx-auto"
      >
        <div className="px-3 py-7 text-center">
          <h2>Why Timber?</h2>
          <p>See why timber is the modern choice for your kitchen.</p>
          <Link className="btn btn-outline-light px-3" to="/timber">
            About Timber
          </Link>
        </div>
      </BackgroundImage>
    </div>

    <div className="container my-3 my-lg-5">
      <div className="py-5 text-center px-3 px-md-5 px-lg-7">
        <h2>Contact Us</h2>
        <p>
          Contact our office with any enquiries or come and see us at our
          showroom in Lonsdale. We are happy to help with any questions you
          have.
        </p>
        <div className="row justify-content-center pt-2">
          <div className="col-sm-6 col-md-6 col-lg-5 d-flex">
            <Link
              className="btn btn-lg btn-primary px-4 mx-2 mb-3 flex-grow-1"
              to="tel:+61881861733"
            >
              <span>Call 08 8186 1733</span>
            </Link>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-5 d-flex">
            <Link
              className="btn btn-lg btn-primary px-4 mx-2 mb-3 flex-grow-1"
              to="/contact/"
            >
              <span>Enquiry Form</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
